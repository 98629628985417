import * as React from 'react';

import {LabelledImageProps} from 'src/components/shared';

import {Maybe, SanityCategory, SanityEditorial, SanityLabelledImage} from 'types/generated';

import EditorialTemplate from './index';

export default ({
  pageContext,
  location,
}: {
  pageContext: {
    editorial: SanityEditorial;
    next?: {
      title: string;
      slug: string;
      heroImage: SanityLabelledImage;
      content: string;
      article: Maybe<SanityEditorial>;
    };
    relatedArticles: [Maybe<SanityEditorial>];

    href: string;
  };
  location: Location;
}): React.ReactElement => (
  <EditorialTemplate
    editorial={pageContext.editorial}
    location={location}
    next={pageContext.next}
    href={pageContext.href}
    isDraft={false}
    relatedArticles={pageContext.relatedArticles}
  />
);
